import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import FunctionsPageTemplate from '../components/Functions/FunctionsPageTemplate';
import MainSection from '../components/Functions/MainSection';
import main from '../assets/images/functions/teamwork/main.png';
import main2x from '../assets/images/functions/teamwork/main_2x.png';
import headLeft from '../assets/images/functions/teamwork/head_left.png';
import headRight from '../assets/images/functions/teamwork/head_right.png';
import HeadSection from '../components/Functions/HeadSection';
import PossibilitiesSection from '../components/Functions/PossibilitiesSection';
import first from '../assets/images/functions/teamwork/1.png';
import first2x from '../assets/images/functions/teamwork/1_2x.png';
import second from '../assets/images/functions/teamwork/2.png';
import second2x from '../assets/images/functions/teamwork/2_2x.png';
import third from '../assets/images/functions/teamwork/3.png';
import third2x from '../assets/images/functions/teamwork/3_2x.png';
import fourth from '../assets/images/functions/teamwork/4.png';
import fourth2x from '../assets/images/functions/teamwork/4_2x.png';
import ImageContainer from '../components/ImageContainer/ImageContainer';
import BlueSpan from '../components/common/typography/BlueSpan';

const faqs = [
  {
    question: 'How do I set up a team?',
    answer:
      'The owner of the main account can invite a participant to a team. In the Settings menu go to Team and press the Invite button. Use the pop-up window to assign profile access rights and provide an email address for the invitation message.',
  },
  {
    question: 'What do I need a team for?',
    answer:
      'Working in a team allows profile sharing without exporting and importing files.',
  },
  {
    question: 'Can we work with the same profile at the same time?',
    answer:
      'It is not possible to work with the same profile simultaneously, but you can do so in turns.',
  },
  {
    question: 'How can I control workflow privacy?',
    answer:
      'You can create and assign unique tags for each team member, who in turn will choose their tag when creating a profile. This way only they and the main account will be able to see these profiles.',
  },
].map((item) => ({
  question: <Trans>{item.question}</Trans>,
  answer: <Trans>{item.answer}</Trans>,
}));

const cardsInfo = [
  {
    title: 'Data sharing',
    description: 'Share profiles, proxies, and cookie files across the team.',
    icon: <ImageContainer src={first} srcSet={{ _2x: first2x }} />,
  },
  {
    title: 'Team roles',
    description:
      'Control the work of every team member using flexible role settings. Control access to profile creating, modifying, deleting, cloning, and transfering.',
    icon: <ImageContainer src={second} srcSet={{ _2x: second2x }} />,
  },
  {
    title: 'Activity history',
    description:
      'Track your team members’ detailed profile interaction activity history.',
    icon: <ImageContainer src={third} srcSet={{ _2x: third2x }} />,
  },
  {
    title: 'Work with tags',
    description:
      'Set up tags for different profile categories to manage your team work. Keep your workflow private.',
    icon: <ImageContainer src={fourth} srcSet={{ _2x: fourth2x }} />,
  },
];

const TeamworkPage = (): JSX.Element => (
  <FunctionsPageTemplate faq={faqs}>
    <HeadSection
      description="Flexible access rights and task allocation   make working in a big team easy in Octo Browser"
      leftBgImage={headLeft}
      rightBgImage={headRight}
      title="Teamwork"
    />
    <MainSection
      description="Control your profiles inside a common workspace using different devices and IP addresses without checkpoints or multi-accounting bans."
      image={main}
      image2x={main2x}
      title={
        <Trans i18nKey="A common workspace">
          A common <BlueSpan>workspace</BlueSpan>
        </Trans>
      }
    />
    <PossibilitiesSection
      cards={cardsInfo}
      title={
        <Trans i18nKey="Teamwork features">
          Teamwork <BlueSpan>features</BlueSpan>
        </Trans>
      }
    />
  </FunctionsPageTemplate>
);

export default TeamworkPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["teamworkPage", "translation"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
